.organ-list {
    display: flex;
    margin: 16px;
    .organ-item {
        background: $color--text-1_05;
        border-radius: 4px;
        color: $color--text-2;
        padding: 8px 12px;
        margin-right: 12px;
        cursor: pointer;
        &.active {
            background: $color--primary;
            color: $color--light;
        }
    }
}
.header-wait-matching {
    display: flex;
    .title {
        color: $color--text-3;
        &:first-child {
            margin-right: 16px;
        }
        &.active {
            color: $color--text-1;
        }
    }
}
