.patient-table {
    &--id {
        color: $color--success-default;
    }
    &--name {
        color: $color--primary;
        &.remove {
            color: $color--danger;
        }
    }
}

.btn {
    &--outlined {
        margin-bottom: 10px;
        width: auto;
        height: $height--header_lesson;

        color: $color--success-default-hover;
        border: 1px solid $color--success-default-hover;
        border-radius: $border-radius--h40;

        &:hover,
        &:active,
        &:visited {
            color: $color--light;
            border: 1px solid $color--success-default-hover;
            background-color: $color--success-default-hover;
        }
        &:focus {
            color: $color--success-default-hover;
            border: 1px solid $color--success-default-hover;
            background-color: $color--light;
        }
    }
}

.pull-right {
    float: right;
}

.title__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.form-modal .ant-form-item.hide-field {
    display: none !important;
}
