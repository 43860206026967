.molecular-biology {
    &__header {
        display: flex;
    }
    &__title {
        flex: 1 1 50%;
        font-size: 24px;
        line-height: 32px;
        font-family: var(--font-bold);
        color: var(--color-text-1);
    }
    &__card {
        box-shadow: $box-shadow--medium;
        border-radius: $border-radius--h64;

        .card__header {
            display: flex;
            .card__title {
                flex: 1 50%;
                font-weight: 500;
                font-size: 24px;
                line-height: 28px;
                color: #052dff;
                margin: 0;
            }
            .button-export {
                border: none;
                background: none;
            }
        }

        .card__content {
            .card__table {
                width: 100%;
                margin-top: 10px;
                text-align: center;

                .table-header {
                    background-color: $color--background;

                    font-weight: 500;
                    font-size: 15.9524px;
                    line-height: 26px;

                    .text {
                        margin: 0;
                    }
                }
            }
        }
    }
    &__group-modal {
        display: flex;
        justify-content: space-between;
    }
    &__button-modal {
        width: auto;
        height: 40px;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 16px;
        border: 1px solid #64a44e;
        color: #64a44e;
        box-sizing: border-box;
        border-radius: $border-radius--h40;
        -webkit-border-radius: $border-radius--h40;
        -moz-border-radius: $border-radius--h40;
        -ms-border-radius: $border-radius--h40;
        -o-border-radius: $border-radius--h40;
    }
}

#molecular-biology__table-data-section {
    margin-top: 50px;
}
