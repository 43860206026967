.kham-lam-sang {
    .button-group {
        display: flex;
        flex-direction: row-reverse;
    }
    .action-button {
        background: $color--success-default;
        color: $color--light;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
