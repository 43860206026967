.recipient-layout {
    .patient-main-container {
        margin-left: 256px;
        width: calc(100vw - 256px);
        position: relative;
        min-height: 100vh;
        background: $color--light;
        transition: $transition-common;
        &.open {
            width: 100vw;
            margin-left: 0;
            transition: $transition-common;
        }
    }
}

.hinh-anh-hoc {
    &__card {
        border: 1px solid $color--gray-line;
        border-radius: $border-radius--h48;

        .card-title {
            color: #052dff;
        }
    }
}

.button-edit {
    border: none;
    background: none;
    &:hover {
        color: $color--pink;
    }
}

.diagnosis-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;
    @include font-24;
}
.header-common.recipient {
    border-left: none;
    .icon-menu {
        margin-right: 12px;
        cursor: pointer;
    }
    .content-right {
        display: flex;
        align-items: center;
    }
    &.dich-mang-bung {
        position: fixed;
        top: 0;
        right: 0;
        height: 40px;
    }
}
.form-data-layout {
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #f9f9f9;
    }

    .left {
        margin-top: 8px;
        margin-bottom: 8px;
        margin-left: 16px;

        font-family: $font--semibold;
    }
    .right {
        margin-right: 16px;
        display: flex;
        color: $color--primary;
        .item {
            margin-left: 16px;
            cursor: pointer;
            color: $color--primary;
            font-size: 12px;
            line-height: 16px;
            svg {
                margin-right: 4px;
                width: 12px;
                height: 12px;
            }
        }
    }
    .section {
        margin-top: 12px;
        margin-left: 12px;

        font-family: $font--semibold;
    }
}
.hoa-sinh {
    .subtab-hoasinh {
        .osn-radio-filter {
            padding-left: 0;
        }
        .osn-radio-filter .ant-radio-button-wrapper {
            padding: 8px 16px;
            transition: 0.3s !important;
        }
        .osn-radio-filter
            .ant-radio-group-solid
            .ant-radio-button-wrapper-checked:not(
                .ant-radio-button-wrapper-disabled
            ) {
            background: $color--primary;
            color: white;
            transition: 0.3s !important;
        }
    }
}
.recipient-status {
    display: flex;
    justify-content: center;
    color: white;
    font-family: $font--medium;
    background: $color--primary;
    font-size: 12px;
    line-height: 16px;
    border-radius: 2px;
    padding: 4px;
    &.loai {
        background: #ff6646;
    }
    &.theo_doi {
        background: #ff9e2b;
    }
}
