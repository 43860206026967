.shpt {
    .dform__page {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        margin-bottom: 15px;
    }
    .ant-select {
        min-width: 100px;
    }
    .dform__collapse {
        margin: 0 auto;
    }
    .data-item-title {
        text-align: center;
    }
    .dform .indent-0 {
        padding: 5px 0 0 0;
    }
}

.checbox-spacing {
    margin: 0 24px;
}

a {
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}

.component-table {
    .ant-table-tbody tr td {
        &:empty {
            &:before {
                content: '--';
            }
        }
    }
}

.nhom-mau {
    .dform__data-item--container {
        display: grid;
        grid-template-columns: 1fr 4fr;
    }
    .dform__page {
        border-top: 1px solid #ccc;
        padding: 0;
    }
    .data-item-title {
        margin: 0;
        border-right: 1px solid #ccc;
        padding: 10px;
    }
    .dform__data-item {
        padding: 0;
        border-bottom: 1px solid #ccc;
    }
    .dform-radio-group {
        padding: 10px 30px;
    }
}

.vi-khuan-chung {
    .ant-input {
        border: 0;
    }
    .dform-radio-group {
        padding: 10px;
    }
    .header {
        padding: 0 0 0 16px;
    }
}
