.reset-password {
    padding: 68px 24px 24px;
    min-height: 100vh;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    &::-webkit-scrollbar {
        display: none;
    }
    .reset-true {
        width: max-content;
        height: max-content;
        padding: 20px;
        margin: auto;
        margin-top: 40vh;
        background-color: $color--purple;
        color: $color--light;
        font-size: 26px;
        font-weight: 500;
        border-radius: 8px;
    }
    .form-wrapper {
        width: 327px;
        margin-left: auto;
        margin-right: auto;
        .ant-form-item-control {
            border-radius: 8px;
        }
    }
    .ant-input-affix-wrapper.ant-input-password.ant-input-password-default {
        border-radius: 8px;
    }
}
