.new-patient-list {
    background-color: $color--light;
    padding: 10px 20px;
    border: 1px solid $color--gray-line;
    border-radius: $border-radius--h64;

    .ant-list-header {
        border-bottom: none;

        .select-input {
            margin-bottom: 0;
        }
    }

    .header-title {
        font-weight: 500;
        font-size: 14px;
    }

    .load-more-button {
        font-size: 14px;
        line-height: 24px;
        border: none;
        color: $color--text-1_85;

        &:hover {
            color: $color--primary;
        }
    }
    .patient-card {
        padding: 10px;
        margin-bottom: 10px;
        background-color: $color--background;
        border: 1px solid $color--gray-line-light !important;
        border-radius: $border-radius--h64;
        cursor: pointer;
        transition: $transition--common;

        &__name {
            font-weight: 600;
            font-size: 14px;
            color: $color--purple;
        }
        &__info {
            font-size: 12px;
            color: $color--text-1;
        }

        &:hover {
            border-color: $color--primary-hover !important;
        }
    }
}

.statistic-chart {
    background-color: $color--light;
    padding: 20px;
    margin: 30px 30px 0 0;

    border: 1px solid $color--gray-line;
    border-radius: $border-radius--h48;
}

.linked-hospitals {
    &__table {
        margin-top: 30px;
    }
}
