.hospital {
    &-title {
        @extend .title;
        box-sizing: border-box;
        padding-bottom: 16px;
    }
    &-dean {
        color: $color--purple;
    }
}
