.form-modal {
    max-width: 750px;

    form {
        row-gap: 20px;
    }

    & label {
        height: auto;
        white-space: normal;
        @include font-14;
        font-family: $font--medium;
        color: $color--text-1;
    }

    .required-field-icon {
        color: $color--danger;
    }
    .label {
        &-normal {
            @include font-14;
            font-family: $font--medium;
            color: $color--text-1;
        }
        &-title {
            @include font-16;
            font-family: $font--semibold;
            color: $color--text-1;
        }
        &-passed {
            @include font-16;
            font-family: $font--regular;
            font-style: italic;
            color: $color--success-default;
        }
    }

    .modal-title {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        &__text {
            @include font-16;
            font-family: $font--semibold;
            color: $color--text-1;
        }
        &__cancel-button {
            cursor: pointer;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background-color: $color--gray-line-light;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }
    .button-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 12px;
        .cancel-button {
            border: 1px solid #d8d8d8;
            box-sizing: border-box;
            border-radius: 4px;
            padding: 8px 12px;
            display: flex;
            align-items: center;
            cursor: pointer;
        }
        .save-button,
        .save-button:hover {
            background: #394bc7;
            border-radius: 4px;
            padding: 8px 12px;
            color: $color--light;
            display: flex;
            align-items: center;
            border: none;
        }
        .ant-form-item {
            margin-bottom: 0;
        }
    }
    .modal-body {
        max-height: calc(100vh - 200px);
        overflow: scroll;

        &::-webkit-scrollbar {
            display: none;
        }

        /* Hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        .ant-form {
            &-item-label {
                display: contents;
                > label {
                    &.ant-form-item-required:not(
                            .ant-form-item-required-mark-optional
                        )::before {
                        position: absolute;
                        left: 100%;
                    }
                    &::after {
                        content: none;
                    }
                }
            }

            .ant-row {
                .ant-col {
                    padding: 0 12px;
                }
                margin-left: -12px;
                margin-right: -12px;
            }
        }
    }

    &.donor-option-modal {
        background-color: red;
        .modal-body {
            height: unset;
        }
    }

    .ant-form-item {
        display: block !important;
        margin-bottom: 0;
        .ant-form-item-row {
            display: flex;
            flex-direction: inherit;

            .ant-form-item-control {
                flex: unset;
                width: 100%;
            }
        }
    }
    .modal-content {
        background: #ffffff;
        box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.4);
        border-radius: 8px;
    }
    .modal-header {
        padding: 8px 24px;
    }
    .input-item .ant-input,
    .input-item .ant-input-number,
    .input-item .ant-select .ant-select-selector {
        border: 1px solid #d8d8d8;
        box-sizing: border-box;
        border-radius: 4px;
    }
    .ems-date-picker {
        border: 1px solid #d8d8d8;
        box-sizing: border-box;
        border-radius: 4px;
        width: 100%;
    }
    .cancel-button {
        border: 1px solid #d8d8d8;
        box-sizing: border-box;
        border-radius: 4px;
        font-family: $font--medium;
        padding: 8px 16px;
        margin-right: 24px;
        background: transparent;
    }
    .modal-footer {
        border-top: none;
        padding-top: 12px;
    }
    &__submission {
        background: $color--primary;
        border-radius: 4px;
        padding: 8px 16px;
        color: $color--light;
        font-family: $font--medium;
        border: none;
        &:disabled {
            background: $color--background;
            color: $color--text-3;
            cursor: not-allowed;
        }
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background-color: $color--background;
    }
    .ant-input.common-input:hover {
        border-color: $color--primary;
    }
    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
        border-color: $color--primary;
    }
    .ant-select:focus,
    .common-input:focus {
        box-shadow: none;
    }
    .ant-select-clear {
        svg {
            width: 12px;
        }
    }
}

.dform-file-input {
    border: 1px solid $color--gray-line;
    border-radius: 4px;
    padding: 7px 11px;
    width: 100%;
    cursor: pointer;
    color: $color--primary;
    font-size: 12px;
    line-height: 16px;
    transition: all 0.3s ease;
    &:hover {
        border-color: $color--primary;
    }
    &.draging {
        border-style: dashed;
        border-color: $color--primary;
    }
    &__container {
        display: flex;
        align-items: center;
    }
    i {
        display: grid;
        min-width: 16px;
        margin-right: 8px;
    }
    &__text {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 8px;
    }
    &__btn-clear {
        display: grid;
        margin-left: auto;
        padding: 0px;
        color: $color--disable;
        background-color: transparent;
        border: none;
        transition: all 0.3s ease;
        &:hover {
            color: rgba(0, 0, 0, 0.45);
        }
    }
    &.readOnly {
        cursor: default;
        .dform-file-input__btn-clear {
            cursor: not-allowed;
        }
    }
    &.disabled {
        cursor: not-allowed;
        .dform-file-input__btn-clear {
            cursor: not-allowed;
        }
    }
}
