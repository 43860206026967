.table-wrapper {
    @include font-14;

    .ant-table-thead {
        & > tr > th {
            background-color: $color--light;
        }
    }

    .column--title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .cells {
        &--wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
        }

        &--unit {
            color: $color--text-3;
        }
    }

    .ant-table-cell {
        min-width: 200px;
        padding: 8px !important;
        vertical-align: baseline;

        &:has(.row--title),
        &.row--title {
            font-family: $font--bold;
            border-radius: 0px !important;
            background-color: #f9f9f9;
            &:hover {
                background-color: #f9f9f9 !important;
            }
        }
    }
}

.no-data {
    &:empty {
        margin: 16px;
        border-radius: 8px;
        border: 2px solid $color--gray-line;
        background: url('/assets/icons/no-data.svg') no-repeat center 30px;
        &::after {
            content: 'Không có dữ liệu để hiển thị';
            display: flex;
            padding: 8px 12px;
            color: $color--text-1;
            border-radius: 4px;
            height: 92px;
            justify-content: center;
            align-items: flex-end;
        }
    }
}
