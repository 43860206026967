.common-container-layout {
    display: flex;
    justify-content: center;
}

.common-content-layout {
    width: 1920px;
    height: 100vh;
}
.bg-login {
    height: 100vh;
    width: 100%;
    object-fit: cover;
}
.logo-hospital {
    width: 100px;
    height: auto;
}
.project-name {
    font-size: 30px;
    line-height: 40px;
    margin-top: 16px;
    margin-bottom: 24px;
    font-family: $font--bold;
}
.title-login {
    @include font-24;
    padding-left: 12px;
    border-left: 4px solid #394bc7;
    font-family: $font--semibold;
    margin-bottom: 24px;
    .text {
        font-size: 11px;
        line-height: 20px;
        color: #696969;
        font-family: $font--regular;
    }
}
.footer-login {
    margin-top: auto;
    a {
        color: #696969;
    }
}
Button.button-submit {
    background-color: #394bc7 !important;
    width: 327px !important;
    height: 48px !important;
    border-radius: 8px !important;
    align-items: center;
    padding: 12px 24px;
    text-align: center;
    color: $color--light !important;
    border: none;
    font-family: $font--semibold;
}
.login-page {
    min-height: 100vh;
    background: white;
    padding-top: 68px;
    padding: 68px 24px 24px;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    &::-webkit-scrollbar {
        display: none;
    }

    .form-wrapper {
        width: 327px;
        margin-left: auto;
        margin-right: auto;
        .password-title {
            display: flex;
            justify-content: space-between;
            margin-bottom: 24px;
            .asterisk {
                display: inline-block;
                color: #ff4d4f;
                font-size: 14px;
                font-family: SimSun, sans-serif;
                line-height: 1;
            }
        }
    }

    .ant-input-affix-wrapper.ant-input-password.ant-input-password-default {
        border-radius: 8px;
    }

    .forgot {
        color: $color--text-1;
    }
    .ant-form-item-required,
    .password {
        font-family: $font--medium;
    }
    .title {
        @include font-24;
    }
}

@media (max-width: 991.98px) {
    .bg-login {
        display: none;
    }
}

.activate-page {
    margin: auto;

    .form-wrapper {
        width: 327px;
        margin-top: 68px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}
