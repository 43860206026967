//custom customize
.ant-descriptions-bordered .ant-descriptions-view {
    border: none;
}
.ant-descriptions-header {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    flex-wrap: wrap;
    flex-direction: row;

    //customize
    background-color: $color--blue-header;
}
.ant-descriptions-title {
    flex: 1;
    overflow: hidden;
    color: $color--text-1_85;
    font-weight: bold;
    font-size: 1.3rem;
    line-height: 1.5715;
    white-space: nowrap;
    text-overflow: ellipsis;

    //customize
    margin: 15px;
    font-family: $font--light;
    font-style: italic;
}

//customize label and content
.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-bordered .ant-descriptions-item-content {
    //customize
    border: 1px solid $color--disable;

    &:last-child {
        //customize
        border-right: 1px solid $color--disable;
    }
}

.ant-descriptions-item-label {
    //customize
    @include font-16;
    width: 25%;
    font-family: $font--semibold;
}

.ant-descriptions-item-content {
    //customize
    @include font-16;
}

.desc-table-wrapper {
    box-shadow: $box-shadow--medium;
    border-radius: $border-radius--h64;

    .button-export {
        border: none;
        background: none;
    }
    .text-update {
        cursor: pointer;
    }
}

.edit-bar {
    @include font-16;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 1rem;
    transition: $transition--common;
    cursor: pointer;
    &:hover {
        color: $color--pink;
    }
}

.status-bar {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 24px;
    justify-content: space-between;
    .button-list {
        width: 345px;
        height: 55px;
        border-radius: 8px;
        background: $color--button;
        @include font-16;
        color: $color--light;
        font-family: $font--bold;
    }
    .button-find {
        width: 153px;
        height: 55px;
        border-radius: 8px;
        background: $color--button;
        @include font-16;
        color: $color--light;
        font-family: $font--bold;
    }
}
