.form-page-detail .form-static-element {
    display: none;
    &.cham-soc-dot-quy-chuyen-biet-no-data {
        display: flex;
        width: 100%;
        .form-static-element-element {
            width: 100%;
            display: flex;
            margin: 24px;
        }
    }

    &.chan-doan-tai-noi-tiep-nhan {
        display: flex;
        width: 100%;

        .form-static-element-element {
            .chan-doan-tai-khoa-tiep-nhan {
                margin: 0px;
                border: none;
            }
            width: 100%;
            display: flex;
        }
    }
}

.form-page-detail .group-data-item {
    display: flex;
    padding: 0;
    .form-block-detail-title {
        display: none;
    }

    &:first-child {
        border-top: 0;
    }

    .form-block-title {
        @include fontSemibold;
        @include font-14;
        width: 40%;
        padding: 6px 12px;
        border-right: 1px solid $color--gray-line;
    }
    .form-block-content {
        margin: 0;
        width: 60%;
        .form-dataitem-title {
            display: none;
        }
    }
}

.form-page-detail .main-checkbox {
    margin: 0;
    padding: 12px 0;
}

.form-page-detail .hide-detail {
    display: none !important;
}

.form-dataitem-value-flatten {
    padding: 8px 12px;
    .form-dataitem-value-title {
        @include fontMedium;
    }
}

.form-page-detail {
    .eGFR {
        .form-dataitem-value.has-children {
            @include fontMedium;
            &::after {
                content: ': ';
            }
        }
    }

    .icu .form-detail-table-2-column:empty {
        border: none;
        &::after {
            display: none;
        }
    }

    .dien-tien-bien-chung--bien-chung {
        display: flex;
        width: 100%;

        .form-static-element-element {
            width: 100%;
        }
    }

    .phan-loai-theo-ascod {
        display: flex;
    }
}

.ant-modal-content {
    .form-block-title {
        @include font-18;
        @include fontMedium;
        margin-top: 6px;
    }

    .form__row {
        margin-bottom: 8px;
    }
}

.form-page-detail {
    .hide-on-detail {
        display: none;
    }
}

.form-page {
    .phan-loai-theo-ascod {
        display: none;
    }
}
