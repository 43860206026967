.receiver-fit {
    &-card {
        display: flex;
        flex-wrap: wrap;
    }

    &-button {
        margin: 14px 0px 34px 0px;
    }

    .info-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 16px;
        margin-bottom: 16px;
    }
}
.group-button {
    height: 40px;
    padding: 0px;
    border: 1px solid $color--gray-line;
    border-radius: $border-radius--h40;
    justify-content: space-between;

    .button-select {
        border: none;
        display: flex;
        align-items: center;
        border-radius: $border-radius--h40;
        padding: 0 16px;
        height: 100%;
        transition: $transition--common;
        &:hover {
            background-color: $color--purple;
            color: $color--light;
        }
    }

    .button-select::before {
        content: '';
        display: block;
        position: absolute;
        right: -1px;
        width: 50%;
        left: 25%;
        border-right: 1px solid red;
    }
    .active {
        background-color: $color--purple;
        color: $color--light;
    }
}
