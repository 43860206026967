@use 'utils' as u;

.form-page-detail {
    //table
    .form-table {
        margin: 0;
    }
    > .form-table {
        margin: u.$padding-main-section-detail;
    }
}
