.recipient-conclusion {
    .info-card {
        width: max-content;
    }
    &__button-group {
        display: flex;
        flex-direction: row-reverse;
        margin-bottom: 16px;
    }
    .action-button {
        background: $color--success-default;
        color: $color--light;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .title {
        margin-bottom: 16px;
    }
}
