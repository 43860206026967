.menu {
    width: 256px;
    height: 100vh;
    position: fixed;
    overflow-y: scroll;
    background: #f9f9f9;
    box-shadow: inset -1px 0px 0px rgba(0, 0, 0, 0.1);

    .ant-menu-submenu-title {
        flex-direction: row-reverse;
    }
    span {
        height: auto;
        white-space: normal;
    }
    .ant-menu-item {
        height: 32px;
        margin-block: 0px;
    }
    .ant-menu-title-content {
        padding-left: 0;
        @include font-14;
        color: $color--text-1;
    }
    .hr {
        height: 3px;
        color: $color--text-1;
    }
    .patientInfo {
        padding: 8px 16px;
        margin-bottom: 8px;
        .patientName {
            @include font-16;
            font-family: $font--semibold;
            color: $color--text-1;
        }
        .patientID {
            @include font-12;
            color: $color--primary;
            margin-bottom: 4px;
        }
    }

    .ant-menu-item {
        border-left: 2px solid transparent;
        padding-left: 16px !important;
        &::after {
            border-right: none;
        }
    }
    .ant-menu-item-active {
        color: $color--primary !important;
        a {
            color: $color--primary;
        }
    }
    .ant-menu-submenu-active {
        .ant-menu-submenu-title {
            &:hover {
                color: $color--primary;
            }
            span {
                color: $color--primary;
            }
        }
    }
    .ant-menu-submenu-selected {
        color: $color--primary;
        .ant-menu-submenu-title {
            span {
                color: $color--primary;
            }
        }
    }
    .item-active {
        color: $color--primary !important;
        border-radius: 0;
        background: rgba(0, 0, 0, 0.05) !important;
        border-left: 2px solid $color--primary-dark;
        a {
            color: $color--primary;
        }
    }

    .ant-menu-item-selected {
        color: $color--primary;
        border-radius: 0;
        background: $color--text-1_05 !important;
        a {
            color: $color--primary;
        }
        &::after {
            border-right: none;
        }
    }
}

.menu-container {
    transition: $transition-common;
    .ant-menu-light .ant-menu-item:hover,
    .ant-menu-light .ant-menu-item-active,
    .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
    .ant-menu-light .ant-menu-submenu-active,
    .ant-menu-light .ant-menu-submenu-title:hover {
        color: $color--primary;
    }
    .ant-menu-item a:hover {
        color: $color--primary;
    }
    .ant-menu-submenu-title {
        display: flex;
        flex-direction: inherit;
        padding-left: 16px !important;
    }
    .ant-menu-sub .ant-menu-title-content {
        padding-left: 16px;
    }
    &.hide {
        display: none;
        transition: $transition-common;
    }
}
.menu::-webkit-scrollbar {
    display: none;
}
