.info-card {
    width: 120px;
    height: 90px;
    background: $color--light;
    border: 1px solid $color--gray-line;
    border-radius: $border-radius--h40;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 8px;
    &__value {
        @include font-24;
        font-family: $font--semibold;
        color: $color--progress;
    }
    &__label {
        @include font-14;
        font-family: $font--medium;
        color: $color--text-1;
    }
}

.info-card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}
