.donor-conclusion {
    &__button-group {
        display: flex;
        justify-content: flex-end;
        .action-button {
            color: $color--light;
            background-color: $color--success-default;
            border-radius: 8px;
        }
        margin-bottom: 5vh;
    }
    .conclusion-title {
        font-weight: bold;
        font-size: 24px;
        font-style: italic;
        margin-bottom: 5vh;
    }
}
