.home-container {
    display: flex;
}
.left-menu-container::-webkit-scrollbar {
    display: none;
}
.left-menu-container {
    position: fixed;
    width: 278px;
    height: 100%;
    background: #f9f9f9;
    box-shadow: 1px 0px 0px $color--text-1_10;
    z-index: 99;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    justify-content: space-between;
    @include font-12;
    transition: $transition-common;
    &.small {
        width: 48px;
        transition: $transition-common;
        .icon-arrow-downup {
            padding: 24px 18px;
            cursor: pointer;
            &:hover {
                background: rgba(0, 0, 0, 0.05);
                border-radius: 4px;
            }
        }
    }

    .header {
        height: 40px;
        padding: 12px 16px;
        box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
    }

    .left-menu-content {
        display: flex;
        flex-direction: column;

        .icon-menu {
            display: flex;
            justify-content: start;
            align-items: center;
            border-radius: 0px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding: 8px 16px;
            &:hover,
            &:active {
                background: rgba(0, 0, 0, 0.05);
            }
        }

        .add-donor-button {
            display: flex;
            align-items: center;
            margin: 8px;
            font-family: $font--medium;
            background: $color--primary-dark;
            color: $color--light;
            &:hover {
                color: $color--light;
                background: $color--primary-dark;
                font-family: $font--medium;
            }
        }

        .list-container {
            padding: 0px 8px;
        }
        .menu-item {
            display: flex;
            align-items: center;
            padding: 8px 16px;
            font-family: $font--medium;
            color: $color--text-1;
            text-decoration: none;
            span {
                margin-left: 16px;
            }
            &:hover {
                background-color: $color--black-8;
            }
            &.sub-menu-item {
                padding-left: 32px;
                cursor: pointer;
                &.active {
                    color: $color--primary;
                    background-color: $color--bg-blue;
                    font-family: $font--semibold;
                }
            }
            &.active {
                color: $color--primary;
            }
        }
        .footer-container {
            display: flex;
            align-items: center;
            padding: 16px;
            cursor: pointer;
            &.ant-space {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            &:hover {
                background: rgba(0, 0, 0, 0.05);
                border-radius: 4px;
            }
            .icon-arrow-downup {
                margin-left: auto;
                cursor: pointer;
            }
            .name {
                font-family: $font--semibold;
                @include font-14;
            }
            .hospital {
                color: $color--text-2;
            }
        }
    }
}
.main-container {
    margin-left: 278px;
    width: calc(100% - 278px);
    position: relative;
    background: $color--light;
    min-height: 100vh;
    &.open {
        margin-left: 48px;
        width: calc(100vw - 48px);
    }
}
.header-container {
    display: flex;
    flex-direction: row;
    .header-content {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-bottom: 24px;
        .path-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px;
            height: 40px;
            .ant-breadcrumb a:hover {
                color: $color--text-1;
            }
            .osn-breadcrumb {
                margin-left: 10px;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                justify-items: center;
                @include font-16;
                font-family: $font--medium;
                color: $color--text-1;
            }
        }
        .account-info {
            padding: 0px;
            height: 48px;
            margin-left: auto;
            margin-right: 20px;
            .acc-name {
                @include font-16;
                font-family: $font--medium;
                color: $color--text-1;
                text-align: right;
                font-weight: bold;
            }
            .acc-working {
                text-align: right;
                font-weight: lighter;
                @include font-14;
                font-family: $font--light;
                color: $color--text-1;
                .role-name {
                    margin-left: 8px;
                    color: $color--success-default;
                }
            }
        }
        .profile-image {
            width: 50px;
            height: 50px;
        }
    }
}
.ant-dropdown {
    .menu-footer {
        width: 278px;
        background: #f9f9f9;
    }
    .icon-menu-item {
        padding: 18px 16px;
    }
}
