body {
    margin: 0;
    font-family: 'Inter-regular', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #111111;
    word-break: break-word;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

@mixin font-24 {
    font-size: 24px;
    line-height: 32px;
}

@mixin font-14 {
    font-size: 14px;
    line-height: 24px;
}

@mixin font-16 {
    font-size: 16px;
    line-height: 24px;
}

@mixin font-17 {
    font-size: 17px;
    line-height: 24px;
}

@mixin font-18 {
    font-size: 18px;
    line-height: 24px;
}

@mixin font-12 {
    font-size: 12px;
    line-height: 16px;
}
@mixin disable-select {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                   supported by Chrome, Opera and Firefox */
}

.title {
    @include font-16;
    font-family: $font--semibold;
    color: $color--text-1;
}
.header-common {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    height: 40px;
    padding: 0 0 0 16px;
    position: sticky;
    top: 0;
    z-index: 100;
    .content-left {
        display: flex;
        align-items: center;
        height: 100%;
        .item {
            display: flex;
            align-items: center;
            padding: 0 12px;
            height: 100%;
            border-left: 1px solid #d8d8d8;
            color: #696969;
            cursor: pointer;
        }
    }
}
.save-button {
    padding: 12px 16px;
    background: #394bc7;
    color: white;
    font-family: $font--medium;
    @include font-12;
    letter-spacing: -0.005em;
    border: none;
    transition: 0.3s;
    &:disabled {
        background: #394bc7;
    }
    &:hove {
        background: $color--primary-hover;
        transition: 0.3s;
    }
}
.actions-common {
    display: flex;
    align-items: center;
    .item {
        padding: 8px;
        cursor: pointer;
    }
}
iframe {
    display: none;
}

.search-layout {
    display: flex;
    align-items: center;
    transition: 0.3s;
    position: relative;
    svg {
        margin-right: 8px;
    }

    .input-search {
        width: 200px;
        border: none;
        margin-right: 10px;

        &.ant-input:focus,
        &.ant-input-focused {
            box-shadow: none;
        }
    }
    .close-search {
        display: inline-flex;
        cursor: pointer;
        position: absolute;
        top: 8px;
        right: -5px;
        margin-right: 0;
        z-index: 30;
    }
}
