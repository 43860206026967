.diagnosis {
    .button-diagnosis {
        margin-top: 5vh;
        margin-bottom: 5vh;
        .ant-btn-group.group-button.align-items-center {
            border-radius: 0px;
            .button-select.active,
            .button-select {
                border-radius: 0px;
            }
        }
    }
    .title-info {
        margin-bottom: 5vh;
    }
    .updateHistory {
        margin-top: 5vh;
        margin-bottom: 5vh;
        .button-add-history {
            padding: 0px;
            border: none;
            background-color: #f5f5f5;
        }
    }
    .button-edit:hover {
        color: $color--pink;
    }

    .card-title,
    .space-edit {
        color: #052dff;
    }
    .ant-typography.card-title {
        font-size: 24px;
    }
}
