.dsTang {
    .organlist {
        margin: auto;
        //margin-top: 100px;
    }
    .ant-descriptions-title {
        text-align: center;
    }
    thead {
        font-family: $font--bold;
    }
    &__organ-label {
        color: $color--text-1;
    }
}
