.forgot-password {
    padding: 68px 24px 24px;
    min-height: 100vh;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    &::-webkit-scrollbar {
        display: none;
    }
    .form-wrapper {
        width: 327px;
        margin-left: auto;
        margin-right: auto;
    }
    .notification {
        color: $color--gray-medium;
        width: max-content;
    }

    .cancel {
        text-align: center;

        .link {
            color: $color--gray-medium;
        }
    }
}
