.filter-item {
    margin-bottom: 16px;
    @include font-12;
    &-label {
        font-family: $font--semibold;
        margin-bottom: 8px;
    }
    &-value-single-tag {
        display: flex;
        flex-wrap: wrap;
        margin: -4px;
        &-item {
            padding: 8px 12px;
            background-color: $color--text-1_05;
            border-radius: 4px;
            margin: 4px;
            cursor: pointer;
            transition: $transition--common;
            &.active {
                color: $color--light;
                background-color: $color--primary;
            }
        }
    }
}
.filter-modal {
    .action-bar {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
        @include font-12;
        .button {
            padding: 8px 16px;
            border-radius: 4px;
            cursor: pointer;
            &.reset {
                color: $color--danger;
                background-color: $color--text-1_05;
            }
            &.apply {
                color: $color--light;
                background-color: $color--primary;
            }
        }
    }
}
.filter-data-range-option {
    flex-grow: 1;
    height: 40px;
    margin: auto;
    .now {
        display: none;
    }
    .ant-picker {
        border: none;
    }
}
.filter-dash-icon-data-range {
    margin: auto;
}
.filter-data-range {
    display: flex;
    height: 40px;
    border: 1px solid $color--gray-line;
    border-radius: 4px;
    width: 320px;
    &-child {
        @include font-12;
        background: none;
        border: none;
        .ant-picker-input {
            @include font-12;
        }
        .ant-form-item-control-input-content {
            width: 150px;
        }
    }
    .input-item {
        width: 150px;
    }
}
