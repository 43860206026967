@use 'sass:math';
.dform {
    $indentsMax: 24;
    @for $i from 0 through $indentsMax {
        .indent-#{$i} {
            padding-left: calc(8px + (8px * #{$i}));
        }
    }

    &__page {
        @include font-14;
        font-family: $font--regular;
        label {
            font-family: $font--regular;
        }
    }

    &__row {
        padding: 8px;
        &--container {
            display: flex;
            flex-wrap: wrap;
            margin-left: -8px;
            margin-right: -8px;
        }
    }

    $grids: 12;
    @for $i from 1 through $grids {
        &__col--#{$i} {
            flex: 0 0 math.div(100%, $i);
        }
    }

    //block

    &__block--header {
        font-family: $font--semibold;
    }

    &__data-item {
        padding: 8px;
        &--container {
            display: flex;
            flex-direction: column;
        }

        &--errors {
            display: grid;
            font-size: 85%;
            line-height: 20px;
            margin-top: 8px;
            color: $color--danger;
            cursor: default;
        }

        .data-item-title {
            margin-bottom: 8px;
            font-family: $font--semibold;
            .required-mark {
                color: $color--danger;
            }
        }
        .common-input {
            width: 100%;
            @include font-14;
            position: relative;
            &.horizontal {
                display: flex;
                flex-wrap: wrap;
            }
            .input-number-wrapper {
                box-shadow: none;
            }
        }
        .ems-date-picker {
            margin-top: 0;
        }
        .number-formatter {
            width: 100%;
            border: 1px solid #d9d9d9;
            padding: 4px 11px;
            border-radius: 2px;
            &:hover {
                border-color: $color--primary;
            }
        }
        .number-unit {
            position: absolute;
            right: 11px;
            top: 9px;
            color: #696969;
            font-size: 12px;
            line-height: 16px;
        }
    }
}
