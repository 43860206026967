.patient-detail-header {
    display: flex;
    svg {
        margin-right: 16px;
    }
}
.parameter {
    display: flex;
    .item {
        border-right: 1px solid #d8d8d8;
        min-width: 184px;
        padding: 8px 16px;
        &__text {
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.005em;
            color: $color--text-2;
        }
        &__value {
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.015em;
            font-family: $font--semibold;
        }
        &:first-child {
            &__text {
                color: $color--primary;
            }
        }
    }
}
.related-info {
    @include font-14;
    font-family: $font--semibold;
    letter-spacing: 0.015em;
    padding: 8px 16px;
    background: #f9f9f9;
    box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1),
        inset 0px 1px 0px rgba(0, 0, 0, 0.1);
}
.patient-detail-info {
    background: $color--light;
    padding: 16px;
    .item {
        display: flex;
        margin-bottom: 8px;
        @include font-12;
        gap: 8px;
        &__title {
            color: $color--text-2;
            letter-spacing: -0.005em;
            width: 170px;
        }
        &__value {
            letter-spacing: 0.015em;
            font-family: $font--semibold;
        }
    }
}
.patient-profile-card {
    width: 350px;
    height: max-content;
    background-color: $color--light;
    padding: 16px;
    @include font-16;
    color: $color--text-1;
    border: 1px solid $color--gray-line;
    border-radius: $border-radius--h40;
    .item-left {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .label-bold {
        font-family: $font--bold;
    }
    .patient-profile-nav {
        @include font-16;
        font-family: $font--bold;
        color: $color--success-default;
    }
}
