.message {
    z-index: $z-index--header;
    .ant-message-custom-content {
        display: flex;
        align-items: center;
    }
}
.icon-toast {
    margin-right: 8px;
}
.ant-message {
    z-index: 9999;
}
