.export-pdf-container {
    .pdf--title {
        display: none;
    }

    .header {
        padding: 8px 12px;
        background-color: $color--primary;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &--left,
        &--right {
            font-family: $font--regular;
            color: $color--light;
            @include font-14;
        }
    }
    .content {
        display: flex;
        justify-content: center;
        padding-top: 24px;
    }

    .content--wrapper {
        display: flex;
        gap: 6px;
        width: 210mm;
        background-color: $color--light;
        padding: 6px 12px;
        flex-direction: column;
        padding-bottom: 24px;

        .section {
            &--item {
                display: flex;
                align-items: center;
                padding-left: 8px;
                gap: 4px;

                &--label {
                    @include font-12;
                    font-family: $font--regular;
                    color: $color--text-2;
                    font-weight: 600;
                    text-wrap: nowrap;

                    &::after {
                        content: ':';
                    }
                }

                &--value {
                    @include font-12;
                    font-family: $font--regular;
                    text-align: justify;
                    text-wrap: nowrap;
                }

                &.description {
                    flex-direction: column;
                    align-items: flex-start;

                    .section--item--value {
                        padding-left: 20px;
                        padding-right: 20px;

                        width: 100%;
                    }
                }
            }
        }

        h3 {
            font-family: $font--semibold;
            font-weight: 600;
            @include font-14;
            margin: 6px 0px;
            width: 100%;
            border-bottom: 1px solid $color--gray-line;
        }

        h4 {
            font-family: $font--semibold;
            @include font-12;
            margin: 0;
        }

        .ant-table {
            @include font-12;
            font-family: $font--regular !important;

            th.ant-table-cell {
                background-color: $color--primary;
                color: $color--light;
                text-align: center;
            }

            .ant-table-cell {
                padding: 2px !important;

                &:empty {
                    content: '--';
                    @include font-12;
                    font-family: $font--regular;
                }
            }

            .ant-empty {
                margin-block: 0px;
                .ant-empty-image {
                    display: none;
                }
                .ant-empty-description {
                    display: none;
                }

                &::after {
                    @include font-12;
                    font-family: $font--regular;
                    content: 'Không có dữ liệu';
                }
            }
        }

        p {
            @include font-12;
            font-family: $font--regular !important;
            margin: 0px;
        }

        .blue {
            color: $color--primary;
        }

        .thoi-gian {
            text-align: left !important;
            width: 130px;
        }
    }

    .empty-block {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $color--gray-line;
        color: rgba(0, 0, 0, 0.25);
        padding: 2px;
    }

    .chan-doan {
        &--wrapper {
            padding: 8px;
        }

        &--item {
            margin: 0px;
            border: 1px solid $color--gray-line;
            padding: 4px 8px;

            &--label {
                @include font-12;
                font-family: $font--regular;
                color: $color--text-2;
                font-weight: 600;
                text-wrap: nowrap;
                margin-right: 4px;

                &::after {
                    content: ':';
                }
            }

            &--value {
                @include font-12;
                font-family: $font--regular;
                text-align: justify;

                &.description {
                    margin-left: 20px;
                }
            }
        }
    }

    .nuoi-cay {
        &--header {
            @include font-12;
            font-family: $font--regular;
            font-weight: 600;

            padding: 2px 8px;
            background-color: $color--primary;
            color: $color--light;
            width: fit-content;
        }

        &--content {
            @include font-12;
            font-family: $font--regular;

            padding: 2px 8px;
            border: 1px solid $color--gray-line;

            .ant-typography {
                @include font-12;
                font-family: $font--regular;

                margin: 0px;
            }
        }
    }
}

.pdf-page-template {
    font-family: $font--regular;
    width: 100%;

    .pdf--title {
        display: block !important;
    }

    .pdf--header {
        position: absolute;
        top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 50px;
        left: 30px;
        width: calc(100% - 60px);
        border-bottom: 1px solid $color--gray-line;

        .left {
            display: flex;
            align-items: center;
            padding-right: 10px;
            height: 100%;
        }

        .right {
            font-size: 10px;
            padding-left: 10px;
            height: 100%;
        }
    }
    .pdf--footer {
        position: absolute;
        bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 50px;
        left: 30px;
        width: calc(100% - 60px);

        .left {
            display: flex;
            gap: 5px;
            font-size: 12px;
            color: $color--text-2;
            align-items: center;
            flex-direction: row;
            padding-right: 10px;
            height: 100%;
        }

        .right {
            display: flex;
            align-items: center;
            color: $color--text-2;
            font-size: 12px;
            padding-left: 10px;
            height: 100%;
        }
    }
}
