@use 'scss/variables' as globalVars;

$padding-main-section-detail: 16px 24px;
@mixin detail-table-view-dot-line {
    content: '';
    display: inline-block;
    width: 6px;
    height: 6px;
    background-color: globalVars.$color--text-1;
    border-radius: 50%;
    position: relative;
    top: 50%;
    transform: translateY(-25%);
    margin-right: 9px;
}
