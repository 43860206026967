.hoa-sinh {
    .mau {
        .mau__button-group {
            display: flex;
            justify-content: flex-end;
            .action-button {
                border-radius: 8px;
                background-color: $color--success-default;

                color: $color--light;
            }
        }
        .duong-huyet {
            .title {
                font-weight: bold;
                color: $color--text-1;
            }
            .glucose {
                background-color: #edefb1;
                font-weight: bold;
            }
        }
    }
}
