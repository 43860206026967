.osn-radio-filter {
    padding-left: 4px;

    .ant-radio-button-wrapper {
        -webkit-transition: none !important;
        -moz-transition: none !important;
        -o-transition: none !important;
        -ms-transition: none !important;
        transition: none !important;
        height: 39px;
        display: flex;
        flex-direction: row;
        align-items: center;
        border: none;
        padding: 0 16px 0 0;
        color: #a6a6a6;
        font-family: $font--semibold;

        &:hover {
            color: $color--text-1;
        }
    }
    .ant-radio-button-wrapper:not(:first-child)::before {
        display: none;
    }
    .ant-radio-button-wrapper-checked:not(
            .ant-radio-button-wrapper-disabled
        )::before {
        background-color: transparent;
    }

    .ant-radio-group-solid
        .ant-radio-button-wrapper-checked:not(
            .ant-radio-button-wrapper-disabled
        ) {
        background-color: white;
        border-color: white;
        color: $color--text-1;
        transition: 0.3s;
    }
    .ant-radio-group {
        display: inline-flex !important;
    }
    .ant-radio-group-solid
        .ant-radio-button-wrapper-checked:not(
            .ant-radio-button-wrapper-disabled
        ):focus-within {
        box-shadow: none;
    }
}
