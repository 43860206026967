@use 'utils' as u;

.form-page-detail {
    > .form__row__detail {
        border: 1px solid $color--gray-line;
        margin: u.$padding-main-section-detail;

        @include for-under-lg {
            margin-left: 16px;
            margin-right: 16px;
        }
    }
    .form__row__detail {
        border-radius: 4px;
        &:empty {
            border: none;
            &::after {
                content: 'Không có dữ liệu';
                display: flex;
                padding: 6px 12px;
                color: $color--text-2;
                border-radius: 4px;
                background: url('/assets/icons/no-data.svg') no-repeat center
                    18px;
                background-color: $color--black_04;
                height: 92px;
                justify-content: center;
                align-items: flex-end;
                padding-bottom: 16px;
            }
        }
    }
}
