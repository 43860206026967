.profile {
    .wrapper {
        width: 327px;

        .ant-picker.ant-picker-large.ems-date-picker {
            width: 300px;
        }
        .button-row {
            display: flex;
            flex-direction: row;
            gap: 16px;
            justify-content: flex-end;
        }
        .ant-btn.ant-btn-lg.save-button {
            background-color: $color--primary;
            color: $color--light;
            border-radius: 4px;
            padding: 8px 16px;
            @include font-12;
        }
        .ant-btn.ant-btn-lg.cancel-button {
            border: 1px solid var(--line, #d8d8d8);
            background: $color--black-5;
            border-radius: 4px;
            padding: 8px 16px;
            @include font-12;
        }
        .item {
            @include font-12;
            color: $color--text-1;
            font-weight: normal;
        }
        .profile {
            display: block;
            width: 100%;
            text-align: center;
            .profileImage {
                width: 190px;
                height: 190px;
                margin-left: auto;
                margin-right: auto;
                border-radius: 95px;
            }
            .updateImage {
                color: $color--button;
                text-decoration: underline;
            }
        }
    }
}
