.donor-layout {
    margin-left: -40px;
    margin-top: -20px;

    .patient-main-container {
        margin-left: 250px;
        padding: 20px 0 52px 40px;
        width: calc(100vw - 250px - 278px);
        position: relative;
    }
}
.common-table-page-header {
    display: flex;
    justify-content: space-between;
    .tab-selector {
        display: flex;
        padding: 0 8px;
        &-item {
            padding: 8px;
            font-family: $font--semibold;
            @include font-16;
            cursor: pointer;
            color: $color--text-3;
            &.active,
            &:hover {
                color: $color--text-1;
            }
        }
    }
}

.ds-nguoi-nhan-phu-hop-header {
    .link {
        color: $color--primary;
        text-decoration: none;
    }
    .info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 16px;
        height: 40px;
        background: #ffffff;
        box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
        &.compare {
            padding-right: 0;
        }
    }
    .name {
        font-size: 16px;
        line-height: 24px;
        font-family: $font--semibold;
        margin-right: 4px;
        margin-left: 16px;
    }
    .code {
        font-size: 14px;
        line-height: 20px;
        color: $color--primary;
    }
    .info-details {
        box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
        display: flex;
    }
    .info-item {
        min-width: 150px;
        border-right: 1px solid #d8d8d8;
        padding: 8px 16px;
        &.last {
            flex-grow: 1;
        }
        &.no-border {
            border-right: none;
        }
        .title {
            color: #696969;
            font-size: 12px;
            line-height: 16px;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .value {
            font-size: 14px;
            line-height: 20px;
            font-family: $font--semibold;
        }
    }
    .matching-button {
        background: $color--primary;
        color: white;
        padding: 0 16px;
        height: 40px;
        border: none;
    }
    .organ {
        padding: 2px 8px;
        background: $color--primary;
        color: white;
        font-size: 14px;
        line-height: 20px;
        border-radius: 2px;
    }
    .compare-details {
        padding: 16px 16px 8px 16px;
        border-left: 1px solid #d8d8d8;
        font-size: 12px;
        line-height: 16px;
        height: 100%;
        .item {
            display: flex;
            margin-bottom: 8px;
            .left {
                width: 130px;
                color: #696969;
                &.patient {
                    background: #49c84e;
                    font-size: 14px;
                    line-height: 20px;
                    color: white;
                    width: max-content;
                    border-radius: 2px;
                    padding: 2px 8px;
                    margin-right: 35px;
                    &.donor {
                        background: $color--primary;
                        margin-right: 38px;
                    }
                }
            }
            .right {
                width: calc(100% - 130px);
                &.code {
                    color: $color--primary;
                    font-size: 12px;
                    line-height: 16px;
                }
                &.patien-name {
                    font-size: 19px;
                    line-height: 24px;
                }
            }
        }
    }
    .renal-parameters {
        padding: 8px 16px;
        font-family: $font--semibold;
        font-size: 12px;
        line-height: 16px;
        background: #f9f9f9;
        box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1),
            inset 0px 1px 0px rgba(0, 0, 0, 0.1);
    }
    .antibody {
        background: #e8edf6;
        border-radius: 2px;
        padding: 2px 8px;
        margin-bottom: 8px;
        max-width: max-content;
    }
    .istance-top {
        margin-bottom: 8px;
    }
    .result-row {
        display: flex;
        justify-content: space-between;
        .action {
            color: $color--primary;
            display: inline-flex;
            align-items: center;
            font-family: $font--regular;
            cursor: pointer;
            svg {
                width: 12px;
                height: 12px;
                margin-right: 4px;
                path {
                    fill: $color--primary;
                }
            }
        }
    }
}
.reason-matching {
    .ant-form-item-label {
        width: 100%;
        max-width: 100%;
    }
    .text-reason {
        color: #ff6646;
        margin-top: 32px;
        margin-bottom: 16px;
    }
    .title-reason {
        font-family: $font--semibold;
        display: flex;
    }
    .star {
        color: $color--danger;
        margin-left: 4px;
    }
}
.emergency {
    background: #ff6646;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-left: 8px;
    display: block;
}
.patient-code-cell {
    display: flex;
    align-items: center;
}
.no-data {
    padding: 16px;
    font-family: 14px;
}
.modal-confirm {
    background: #ffffff;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.4);
    border-radius: 8px;
    width: 400px;
    .modal-body {
        padding: 24px;
        .title {
            font-size: 16px;
            line-height: 24px;
            font-family: $font--semibold;
            text-align: center;
        }
        .text {
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            margin: 12px 0 24px 0;
        }
    }
    .button-row {
        display: flex;
        justify-content: space-between;
        .confirm {
            width: 170px;
            font-size: $font--semibold;
            background: #f5f5f5;
            border-radius: 8px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            &.save {
                background: #ff5f73;
                color: white;
            }
        }
    }
}
.header-common.donor {
    border-left: none;
    .icon-menu {
        display: flex;
        align-items: center;
        justify-self: center;
    }
    .content-right {
        display: flex;
        align-items: center;

        .tabs {
            display: flex;
            gap: 8px;
            margin-left: 16px;

            & > * {
                @include font-14;
                font-family: $font--semibold;

                color: $color--text-3;

                &.active {
                    color: $color--text-1;
                }

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}
.subtabs {
    display: flex;
    gap: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    flex-direction: row;
    flex-wrap: wrap;

    & > * {
        @include font-14;
        font-family: $font--semibold;
        padding: 8px;

        color: $color--text-3;

        &.active {
            background-color: $color--primary;
            color: $color--light !important;
        }

        &:hover {
            text-decoration: none;
            color: $color--text-1;
        }
    }
}
.menu-create-form.ant-dropdown-menu {
    min-width: 300px;
}

.modal-view-chart {
    .ant-modal-body {
        /* Hide scrollbar for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
            display: none;
        }

        /* Hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        .chart--wrapper {
            min-height: 100px;

            .chart--title {
                @include font-14;
                font-family: $font--semibold;
            }

            &--item {
                margin-bottom: 16px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &:empty {
                background: url('/assets/icons/no-data.svg') no-repeat center
                    18px;
                &::after {
                    content: 'Vui lòng chọn ít nhất 1 biểu đồ để xem';
                    display: flex;
                    padding: 6px 12px;
                    color: $color--text-1;
                    border-radius: 4px;
                    height: 92px;
                    justify-content: center;
                    align-items: flex-end;
                    padding-bottom: 16px;
                    margin-bottom: 16px;
                }
            }

            .chart--empty {
                border: 1px solid $color--gray;
                border-radius: 4px;

                background: url('/assets/icons/no-data.svg') no-repeat center
                    18px;
                &::after {
                    content: 'Không có dữ liệu';
                    display: flex;
                    padding: 6px 12px;
                    color: $color--text-1;
                    border-radius: 4px;
                    height: 92px;
                    justify-content: center;
                    align-items: flex-end;
                    padding-bottom: 16px;
                }
            }
        }
    }
}

.chart-line {
    height: 100%;
}
.modal-dialog.modal-view-chart {
    width: 800px;
    max-width: initial;
    .chart-container {
        height: 250px;
        margin: 36px 0;
    }
}
.row-action {
    display: flex;
    align-items: center;
    .view-file {
        cursor: pointer;
        svg {
            width: 16px;
            height: 16px;
            margin-left: 16px;
        }
    }
}
.description-chan-doan {
    max-width: 300px;
}
.description-lam-sang {
    max-width: 150px;
}
.description-nhiem-khuan {
    max-width: 400px;
}
.description-hinh_anh_hoc {
    max-width: 300px;
}
.description-xet-nghiem_khac {
    width: 300px;
}
.code-column {
    max-width: 200px;
}
