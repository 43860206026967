.input-item {
    .required-notation {
        color: $color--danger;
        margin-left: 4px;
    }
    .error-message {
        transform: scaleY(1);
        transform-origin: top;
        transition: all 0.2s;
        height: 15px;
        &.hide {
            transform: scaleY(0);
            height: 0px;
        }
    }
    .ant-input,
    .ant-input-number,
    .ant-select .ant-select-selector,
    .ant-input-password {
        @include font-14;
        width: 100%;
        border-radius: 8px;
        border: 1px solid $color--gray-line !important;
        box-shadow: none !important;
    }
    .ant-input-affix-wrapper > input.ant-input {
        border: none !important;
    }
    .ant-select-dropdown {
        border-radius: 8px;
    }
    .ant-select-single {
        width: 100%;
        .ant-select-selector {
            .ant-select-arrow {
                top: 50%;
                width: 16px;
                height: 16px;
            }
        }
    }
    .ant-select-item-option {
        padding: 8px 12px;
    }
    .custom-input-vertical {
        padding: 12px 16px;
        border-radius: $border-radius--h48;
        &:hover,
        &:focus {
            border-color: $color--gray-line;
            box-shadow: none;
        }
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        color: $color--text-1;
        font-weight: unset;
        background-color: $color--light;
    }
}
.file-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    border-radius: 8px;
    border: 1px solid $color--gray-line;
    padding-left: 12px;
    padding-right: 4px;
    max-width: 485px;
    .file-names {
        @include font-14;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .action-button {
        padding: 4px 12px;
        background-color: $color--background;
        cursor: pointer;
        border-radius: 6px;
        height: 32px;
        min-width: 50px;
        &.remove {
            color: $color--danger;
            &:hover {
                color: $color--light;
                background-color: $color--danger;
            }
        }
        &.upload {
            color: $color--success-default-hover;
            &:hover {
                color: $color--light;
                background-color: $color--success-default;
            }
        }
    }
}
.invisible {
    display: none !important;
}

.ant-form-item-label-left
    .ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    position: absolute;
    left: 100%;
}
.ant-form-item-explain-error {
    font-size: 11px;
    line-height: 14px;
}
.ant-btn-primary {
    box-shadow: none;
}

.login-page,
.forgot-password,
.reset-password {
    .ant-input,
    .ant-input-number,
    .ant-select .ant-select-selector,
    .ant-input-password {
        padding: 8px 16px;
    }
}
