.conclusionDonor {
    margin-top: 15vh;
    .content {
        display: flex;
        justify-content: space-between;
        span {
            color: $color--pink;
        }
    }
}
.indexConclusion {
    .infocard-card {
        width: 200px;
        height: 100px;
        background: $color--light;
        border: 1px solid $color--gray-line;
        border-radius: $border-radius--h40;
        margin: 20px 20px 20px 0px;
    }

    .organList {
        width: fit-content;
        height: fit-content;
        margin-top: 20px;
        margin-left: 10px;
        font-style: normal;
        font-weight: 600;
        @include font-24;
        letter-spacing: 0.02em;
        color: $color--progress;
    }
    .text {
        height: fit-content;
        width: fit-content;
        margin-top: 5px;
        margin-left: 10px;
        @include font-14;
        line-height: 20px;
        letter-spacing: 0.005em;
        color: $color--text-1;
    }
}
