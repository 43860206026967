.ems-table {
    box-shadow: none;
    border-radius: 0;
    overflow: hidden;
    padding-left: 4px;
    .ant-table {
        @include font-14;
    }
    .ant-table-wrapper {
        overflow-x: auto;
        height: calc(100vh - 88px);
        .ant-table-thead {
            .ant-table-cell {
                background: $color--light;
                border-bottom: none;
                padding: 12px;
                font-family: $font--semibold;
                &::before {
                    width: 0 !important;
                }
                .ant-table-filter-trigger:hover {
                    background: $color--light;
                }
            }
        }
        .ant-table-tbody {
            .ant-table-row {
                .ant-table-cell {
                    border-bottom: none;
                    padding: 8px 12px;
                }
            }
        }
        .ant-spin-nested-loading {
            .ant-spin {
                max-height: none;
            }
        }
        .ant-table-pagination.ant-pagination {
            padding: 8px 12px;
            background: $color--light;
            margin: 0;
            box-shadow: $inner-shadow--line-top;
            .ant-pagination-item-link {
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .ant-pagination-item-link {
                border: none;
                background: $color--background;
                border-radius: 4px;
            }
            .ant-pagination-item {
                border: 1px solid $color--gray-line;
            }
        }
    }
    .ant-table-row-indent + .ant-table-row-expand-icon {
        background-color: $color--text-1;
        border: none;
        &:active {
            outline: none;
        }
        &:hover {
            color: $color--text-1;
            border: none;
        }
        margin-right: 14px;
        &::before {
            content: none;
        }
        &::after {
            top: 0px;
            left: 0px;
            width: 16px;
            height: 16px;
            background: url(../assets/icons/arrow_expand_collapse_right_down.svg);
            background-position: center;
        }
        &-expanded::after {
            transform: rotate(0deg);
        }
        &-collapsed::after {
            transform: rotate(-90deg);
        }
        &-spaced {
            background: $color--disable;
            visibility: unset;
            border-radius: 50%;
            max-width: 8px;
            min-width: 8px;
            height: 8px;
            margin-top: 6.5px;
            margin-left: 4px;
            cursor: default;
        }
    }
    .ant-table-tbody > tr > td {
        border-bottom: none;
    }
    .color-icon-search {
        color: $color--disable;
        &.active {
            color: $color--progress;
        }
    }
    .ant-table-column-sorter-up.active,
    .ant-table-column-sorter-down.active {
        color: $color--progress;
    }
}
.ems-table.matching-waiting-list {
    .ant-table-wrapper {
        overflow-x: auto;
        height: calc(100vh - 160px);
    }
}
.ant-table-filter-dropdown {
    .search {
        box-shadow: $box-shadow--big;
        border-radius: 12px;
        padding: 16px;
        margin-top: 5px;
        &__input {
            border: 1px solid $color--gray-line;
            box-sizing: border-box;
            border-radius: 6px;
            margin-bottom: 16px;
            max-width: 246px;
            display: block;
        }
        &__reset {
            border: none;
            margin-left: auto;
            color: $color--text-2;
            border: none;
        }
        &__done {
            background: $color--primary-hover;
            border-radius: 6px;
            min-height: 32px;
            color: $color--light;
            border: none;
            &:hover {
                background: $color--primary-light;
            }
        }
    }
    .checkbox-group {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 230px;
        .ant-checkbox-wrapper {
            margin-left: 0;
        }
        .checkbox-wrapper {
            border-radius: 6px;
            padding: 6px;
            margin-bottom: 2px;
            &.checked,
            &:hover {
                background-color: rgba($color: $color--text-1, $alpha: 0.05);
            }
            &:last-child {
                margin-bottom: 16px;
            }
        }
        .ant-checkbox-checked .ant-checkbox-inner {
            border-radius: 4px;
            background-color: $color--primary-hover;
            border-color: $color--primary-hover;
        }
    }
}
.user-table {
    .role-cell {
        &.multirow {
            padding-left: 10px;
            position: relative;
            .items::before {
                top: 4px;
                left: 0px;
                content: '';
                width: 2px;
                height: calc(100% - 8px);
                border-radius: 1px;
                background-color: $color--disable;
                position: absolute;
                display: block;
            }
        }
    }
    .actions-cell {
        display: flex;
        cursor: pointer;
    }
    .fullname-cell {
        display: flex;
        align-items: center;
        a {
            text-decoration: none;
        }
        svg {
            margin-right: 8px;
            min-width: 16px;
            min-height: 16px;
        }
    }
}

.ems-pagination {
    background: $color--light;
    padding: 8px 12px;
    box-shadow: $inner-shadow--line-top;
    display: flex;
    position: fixed;
    bottom: 0;
    width: calc(100% - 278px);
    z-index: 10;
    .ant-pagination-total-text {
        margin-right: auto;
    }
    .ant-pagination-item-link {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background: $color--background;
        border-radius: 4px;
    }
    .ant-pagination-item:hover {
        border-color: $color--primary-hover;
    }
    .ant-pagination-item-active {
        border-color: $color--primary-hover;
    }
    .ant-pagination-jump-next
        .ant-pagination-jump-prev:focus-visible
        .ant-pagination-item-link-icon,
    .ant-pagination-jump-next:focus-visible .ant-pagination-item-link-icon,
    .ant-pagination-jump-prev:hover .ant-pagination-item-link-icon,
    .ant-pagination-jump-next:hover {
        .ant-pagination-item-container {
            display: flex;
            height: 32px;
            align-items: center;
        }
        .ant-pagination-item-link-icon {
            color: $color--primary;
        }
    }
}
