.tien-can {
    @include font-14;
    padding-bottom: 12px;
    .right-item {
        display: flex;
        flex-direction: row-reverse;
        .item-export {
            cursor: pointer;
        }
        &.hover {
            color: $color--pink;
        }
    }
    .main {
        background: $color--light;
        border-radius: 8px;
        box-shadow: 0px 8px 24px $color--text-1_13;
        box-sizing: border-box;
        padding: 24px;

        .tien-can-table {
            padding: 40px;
            &--label {
                @include font-16;
                font-family: $font--medium;
                color: $color--text-1;
            }
            &--value {
                @include font-16;
                font-family: $font--regular;
                color: $color--text-1;
            }
        }
    }
    .form-modal-tien-can {
        width: 1165px;
    }
    .nhom-mau-layout {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 16px;
        &.donor {
            .save-button {
                position: fixed;
                top: 0;
                right: 0;
                z-index: 100;
            }
            .title {
                margin-top: 16px;
            }
        }
    }
    .title-form {
        font-family: $font--semibold;
    }
    .ket-qua-cong-thuc-mau {
        .title-form {
            padding: 16px 16px 8px 16px;
        }
        .osn-radio-filter {
            padding: 0 16px 0 16px;
        }
    }
    .dform__block--header {
        margin: 12px 0 0 8px;
    }
}
