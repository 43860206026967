.staff {
    .staff-name {
        color: $color--purple;
    }
    .staff-bar {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
        box-sizing: border-box;
        padding-bottom: 8px;
    }
    .btn--add-staff {
        width: 170px;
        height: 40px;
        background: $color--success-default;
        color: $color--light;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.menuaction {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 135.19px;
    height: 73px;
    border: 1px solid $color--text-1;
    background-color: $color--light;
    &-button {
        border: none;
    }
    &-button:hover {
        color: $color--success-default-hover;
    }
}
