.comparison {
    .header {
        width: 100%;
        //padding: 24px 0;
        margin-bottom: 24px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .left {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .title-big {
                @include font-24;
                font-family: $font--bold;
                color: $color--text-1;
            }
            .title-small {
                @include font-16;
                font-family: $font--bold;
                color: $color--text-1;
            }
        }
        .button-center,
        .button-right {
            width: max-content;
            height: 50px;
            border-radius: 8px;
            background: $color--success-default;
            @include font-16;
            color: $color--light;
            font-family: $font--bold;
        }
        .button-center {
            margin-right: 15vw;
        }
    }
    .og-container {
        margin-top: 24px;
        padding: 16px;
        border-radius: 8px;
        background: $color--light;
        .title {
            @include font-16;
            color: $color--text-1;
            font-family: $font--medium;
        }
        .accessment-container {
            margin: 48px 0;
            & > * {
                margin: 32px 0;
            }
        }
    }
}

.accessment-table {
    margin-top: 8px;
    border-radius: 0px;

    .ant-table-wrapper {
        .ant-table-thead {
            @include font-14;
            font-family: $font--bold;
            color: $color--text-1;
            .ant-table-cell {
                background: $color--bright-gray;
            }
        }

        .ant-table-tbody {
            .ant-table-row {
                .ant-table-cell {
                    border-bottom: 1px solid $color--silver-foil;
                }
            }
        }
    }
}

.donor-recipient-tbl {
    border-radius: 16px;
    .ant-table-wrapper {
        & table {
            border-radius: 16px;
        }
        overflow: hidden;
        .ant-table-thead {
            @include font-24;
            font-family: $font--bold;
            color: $color--text-1;
            .ant-table-cell {
                border: 1px solid $color--silver-foil;
                background: $color--light-gray;
            }
        }
        .ant-table-tbody > tr > td:first-child {
            @include font-14;
            font-family: $font--bold;
            color: $color--text-1;
        }
        .ant-table-tbody {
            .ant-table-row {
                .ant-table-cell {
                    border: 1px solid $color--silver-foil;
                    padding: 8px 12px;
                }
            }
        }
    }
}
