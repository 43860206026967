$color--bg-blue: #e8edf6;
$color--bg-hover: #efefef;
$color--bg: #f9f9f9;
$color--black-8: rgba(0, 0, 0, 0.08);
$color--blue: #394bc7;
$color--line: #d8d8d8;
$color--text-1: #000000;
$color--text-2: #696969;
$color--text-3: #9d9d9d;
$color--text-4: #5a5a5a;
$color--white: #ffffff;
$color--red: #ff6646;
$color--green: #34ab71;

/* ----- -------------------------------------------------------------------------------- */
/*
 * color text.
 */
$color--text-1_70: rgba(#000000, $alpha: 0.3);
$color--text-1_10: rgba(
    $color: var(--RGB_color-text-1),
    $alpha: 0.1,
);
$color--text-1_05: rgba(
    $color: var(--RGB_color-text-1),
    $alpha: 0.05,
);
$color--text-1_13: rgba(
    $color: var(--RGB_color-text-1),
    $alpha: 0.13,
);
$color--text-1_85: rgba(
    $color: var(--RGB_color-text-1),
    $alpha: 0.85,
);
$color--disable: var(--color-disable);
$color--light: var(--color-light);
$color--text-1_80: rgba(
    $color: $color--text-1,
    $alpha: 0.8,
);
$color--light_10: rgba(
    $color: $color--light,
    $alpha: 0.1,
);
$color--light_15: rgba(
    $color: $color--light,
    $alpha: 0.15,
);
$color--light_20: rgba(
    $color: $color--light,
    $alpha: 0.2,
);
$color--light_25: rgba(
    $color: $color--light,
    $alpha: 0.25,
);
$color--light_50: rgba(
    $color: $color--light,
    $alpha: 0.5,
);
$color--gray-medium: var(--color-gray-medium);
$color--pink: var(--color-pink);
$color--purple: var(--color-purple);

/*
 * color gray.
 */
$color--background: var(--color-background);
$color--gray-line: var(--color-gray-line);
$color--gray-line-light: var(--color-gray-line-light);

/*
 * color primary.
 */
$color--primary: var(--color-primary);
$color--primary-light: var(--color-primary-light);
$color--primary-dark: var(--color-primary-dark);
$color--primary-hover: var(--color-primary-hover);
$color--primary-background: var(--color-primary-background);
$color--primary-red: var(--color-primary-logout);

/*
 * color status.
 */
$color--progress: var(--color-progress);
$color--progress-dark: var(--color-progress-dark);
$color--danger: var(--color-danger);
$color--danger-dark: var(--color-danger);
$color--success-default-hover: var(--color-success-default-hover);
$color--success-default: var(--color-success-default);

$color--silver-foil: var(--color-silver-foil);
$color--bright-gray: var(--color-bright-gray);
$color--gray: var(--color-gray);
$color--light-gray: var(--color-light-gray);
$color--blue-header: var(--color-blue-header);
$color--button: var(--color-button);
$color--light-blue: var(--color-light-blue);
$color--black-5: var(--color-black-5);
/*
 * shadow.
 */
$box-shadow--small: var(--box-shadow-small);
$box-shadow--medium: var(--box-shadow-medium);
$box-shadow--big: var(--box-shadow-big);
$box-shadow--light: var(--box-shadow-light);
$inner-shadow: var(--inner-shadow);
$inner-shadow--line-bot: var(--inner-shadow-line-bot);
$inner-shadow--line-top: var(--inner-shadow-line-top);

/*
 * border radius.
 */
$border-radius--h64: var(--border-radius-h64);
$border-radius--h48: var(--border-radius-h48);
$border-radius--h40: var(--border-radius-h40);

/*
 * font.
 */
$font--regular: var(--font-regular);
$font--semibold: var(--font-semibold);
$font--bold: var(--font-bold);
$font--light: var(--font-light);
$font--medium: var(--font-medium);

/*
 * constant.
 */
$z-index--header: var(--z-index-header);
$transition--common: var(--transition-common);

$height--header_lesson: var(--height-header-lesson);

/*
* Set default constant
*/
$default-constant: (
    --z-index-header: 1000,
    --transition-common: all 0.3s ease,
    --height-header-lesson: 48px,
);
@mixin spread-map($map: ()) {
    @each $key, $value in $map {
        #{$key}: $value;
    }
}
:root {
    @include spread-map($default-constant);
}

/**
* transition
**/
$transition-common: all 0.3s ease;

$color--light: #ffffff;
$color--light_04: rgba(
    $color: $color--light,
    $alpha: 0.04,
);
$color--light_10: rgba(
    $color: $color--light,
    $alpha: 0.1,
);
$color--light_15: rgba(
    $color: $color--light,
    $alpha: 0.15,
);
$color--light_20: rgba(
    $color: $color--light,
    $alpha: 0.2,
);
$color--light_25: rgba(
    $color: $color--light,
    $alpha: 0.25,
);
$color--light_50: rgba(
    $color: $color--light,
    $alpha: 0.5,
);
$color--light_80: rgba(
    $color: $color--light,
    $alpha: 0.8,
);

$color--black: #000;

$color--black_04: rgba(
    $color: $color--black,
    $alpha: 0.04,
);

$color--light_10: rgba(
    $color: $color--black,
    $alpha: 0.1,
);

$color--black_04: rgba(
    $color: $color--black,
    $alpha: 0.04,
);

$color--text1: #111111;
$color--text1_04: rgba(
    $color: $color--text1,
    $alpha: 0.04,
);
$color--text1_05: rgba(
    $color: $color--text1,
    $alpha: 0.05,
);
$color--text1_08: rgba(
    $color: $color--text1,
    $alpha: 0.08,
);
$color--text1_70: rgba(
    $color: $color--text1,
    $alpha: 0.7,
);
$color--text1_80: rgba(
    $color: $color--text1,
    $alpha: 0.8,
);

$color--completed: #2aad67;
$color--gold: #f4c25b;
$color--orange: #ff9e2b;
$color--gray: #969696;
$color--red: #ff4343;

$color--primary: #1959cf;
$color--primary-dark: #1748a2;
$color--primary-light: #e4ebfb;
