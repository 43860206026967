.main-profile {
    background: $color--light;
    box-sizing: border-box;
    padding: 24px;
    margin-top: 20px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    & > * {
        flex-grow: 1;
    }

    .profile-table {
        padding: 40px;
        &--label {
            @include font-16;
            font-family: $font--medium;
            color: $color--text-1;
        }
        &--value {
            @include font-16;
            font-family: $font--regular;
            color: $color--text-1;
        }
    }
    .profile-content {
        width: 423px;
        gap: 16px;
    }
    .button-row {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .save-button {
            width: 80px;
            height: 40px;
            background: $color--primary;
            color: $color--light;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.button-change {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 24px 0px;
    justify-content: space-between;
    .profile {
        width: 200px;
        height: 30px;
        border-radius: 8px;
        background: $color--button;
        @include font-14;
        color: $color--light;
        font-family: $font--bold;
    }
    .password {
        width: 153px;
        height: 30px;
        border-radius: 8px;
        background: $color--button;
        @include font-14;
        color: $color--light;
        font-family: $font--bold;
    }
}
.profile-tab-container {
    padding-left: 8px;
    .osn-radio-filter .ant-radio-button-wrapper {
        margin: 0 8px;
        padding: 0;
        border-bottom: 2px solid transparent;
        transition: 0.3s;
    }
    .osn-radio-filter
        .ant-radio-group-solid
        .ant-radio-button-wrapper-checked:not(
            .ant-radio-button-wrapper-disabled
        ) {
        border-bottom: 2px solid #000000;
        transition: 0.3s;
    }
}

.profile {
    width: 423px;
    padding: 48px;
    align-items: center;
    margin: auto;
    gap: 16px;

    .profile-wrapper {
        display: flex;
        height: 56px;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        flex: 1 0 0;
        @include font-12;

        .title {
            color: $color--text-1;
            font-family: $font--semibold;
            @include font-24;
        }

        .info {
            display: flex;
            gap: 0.18rem;

            .position {
                color: $color--light-blue;
            }

            .work-place {
                font-weight: 600;
            }
        }

        .seperate {
            height: 1px;
            color: $color--gray;
            background-color: $color--gray;
        }
    }

    .profile-table {
        display: flex;
        flex-direction: column;
    }

    .profile-item {
        display: flex;
        gap: 19px;
        padding: 3px 0;

        .title {
            width: 90px;
            @include font-12;
            font-family: $font--semibold;
        }

        .value {
            align-items: flex-start;
            @include font-12;
        }
    }
}

.url-options {
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: $color--primary;
    @include font-12;

    .logout {
        cursor: pointer;
        color: $color--primary-red;
    }

    .change-profile,
    .change-password {
        text-decoration-line: none;
    }
}

.ant-form-item-required {
    font-family: $font--semibold;
    @include font-12;
    flex-direction: row-reverse;
    gap: 3px;
}
