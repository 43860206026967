::-webkit-scrollbar {
    width: 10px;
    height: 6px;
}

::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
}

::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}
