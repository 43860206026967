@import 'fonts';
@import 'variables';
@import 'common';
@import 'home';
@import 'table';
@import 'message';
@import 'login';
@import 'inputs';
@import 'date';
@import 'patient';
@import 'staff';
@import 'hospital';
@import 'forgotpassword';
@import 'resetpassword';
@import 'formmodal';
@import 'descriptionsTable';
@import 'comparison';
@import 'leftmenupatient';
@import 'tiencan';
@import 'recipientlayout';
@import 'patientnprofile';
@import 'receiver';
@import 'ghichu';
@import 'diagnosisTable';
@import 'conclusionDonor';
@import 'radiofilter';
@import 'account';
@import 'confirm';
@import 'dashboard';
@import 'crosscoupling';
@import 'receiverfit';
@import 'donorLayout';
@import 'dsTang';
@import 'clinical';
@import 'staffProfile';
@import 'form_module';
@import 'analysis_result';
@import 'infocard';
@import 'visinh';
@import 'optionlist';
@import 'kham-lam-sang';
@import 'recipientconclusion';
@import 'donorconclusion';
@import 'filter';
@import 'danh-sach-cho-ghep';
@import 'file-preview';
@import 'menu';
@import 'scrollbar';
@import 'verticalTable';
@import 'overWrite';
@import 'notification';
@import 'export-pdf';
@import 'forms';
@import 'blockCmv';

.ant-picker-datetime-panel {
    flex-direction: row-reverse;
}
