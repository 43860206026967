.cross-coupling {
    .info-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 16px;
    }
    .info-patient-card {
        width: 400px;
        height: max-content;
        border-radius: 16px;
        background-color: $color--light;
        border: 1px solid $color--gray-line;
        border-radius: $border-radius--h40;
        padding: 16px;
        @include font-16;

        color: $color--text-1;
        .item-left {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .item-right {
            .patient-type {
                background-color: $color--purple;
                color: $color--light;
                padding: 4px;
                border-radius: 4px;
                float: right;
            }
        }
        .profile-nav {
            @include font-16;
            font-family: $font--bold;
            color: $color--success-default;
        }
        .label-bold {
            font-family: $font--bold;
        }
    }
    .cell-id {
        color: $color--success-default;
    }
    .cell-name {
        color: $color--purple;
    }

    &__table-row {
        cursor: pointer;
    }
}
