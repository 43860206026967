.receiver {
    .receiver-table {
        .id-cell {
            color: $color--primary;
        }
        .name-cell {
            color: $color--primary;
        }
        .action {
            .name-cell {
                padding-right: 16px;
                color: $color--gray-medium;
                .icon-toast {
                    circle {
                        fill: $color--gray-medium;
                    }
                }
                &.disabled {
                    color: $color--gray-line-light;
                    cursor: not-allowed;
                }
            }
        }
        .status {
            &-requested {
                color: $color--text-1;
            }
            &-approved {
                background: $color--primary;
                padding: 4px 8px;
                min-width: 65px;
                text-align: center;
                border-radius: 2px;
                color: white;
                font-size: 12px;
                line-height: 16px;
            }
            &-pending {
                background: #ff9e2b;
                padding: 4px 8px;
                min-width: 65px;
                text-align: center;
                border-radius: 2px;
                color: white;
                font-size: 12px;
                line-height: 16px;
            }
            &-rejected {
                background: $color--danger;
                padding: 4px 8px;
                min-width: 65px;
                text-align: center;
                border-radius: 2px;
                color: white;
                font-size: 12px;
                line-height: 16px;
            }
            &-selected {
                background-color: #49c84e;
                padding: 4px 8px;
                min-width: 65px;
                text-align: center;
                border-radius: 2px;
                color: white;
                font-size: 12px;
                line-height: 16px;
            }
        }
    }
}
