.clinical {
    .button-add-data {
        border: 0px;
        padding: 0px;
        color: #000aff;
        background-color: #f5f5f5;
    }
    .dataTable {
        margin-bottom: 10vh;
        thead {
            background-color: #000aff;
        }
    }
}
