.ghichu {
    &-title {
        flex: 1 1 50%;
        font-size: 24px;
        line-height: 32px;
        font-family: var(--font-bold);
        color: var(--color-text-1);
    }
    &-card-title {
        flex: 1 50%;
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        color: #052dff;
        margin: 0;
    }
    &-button {
        border: none;
    }
    &-export {
        color: #052dff;
        display: flex;
        justify-content: flex-end;
    }
    &-divider {
        background-color: rgba(0, 0, 0, 0.21);
    }
    &-notes-table {
        margin-top: 2vw;
    }
}
